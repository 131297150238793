exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-type-choreography-js": () => import("./../../../src/pages/portfolio/type/choreography.js" /* webpackChunkName: "component---src-pages-portfolio-type-choreography-js" */),
  "component---src-pages-portfolio-type-collaboration-js": () => import("./../../../src/pages/portfolio/type/collaboration.js" /* webpackChunkName: "component---src-pages-portfolio-type-collaboration-js" */),
  "component---src-pages-portfolio-type-performance-js": () => import("./../../../src/pages/portfolio/type/performance.js" /* webpackChunkName: "component---src-pages-portfolio-type-performance-js" */),
  "component---src-pages-portfolio-type-workshop-js": () => import("./../../../src/pages/portfolio/type/workshop.js" /* webpackChunkName: "component---src-pages-portfolio-type-workshop-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-portfolio-item-page-js": () => import("./../../../src/templates/portfolio-item-page.js" /* webpackChunkName: "component---src-templates-portfolio-item-page-js" */)
}

